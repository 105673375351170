import { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import CTA from "../components/LandingPage/CTA";
import FeatureCarousel from "../components/LandingPage/FeatureCarousel/FeatureCarousel";
import HeroCarousel from "../components/LandingPage/HeroCarousel";
import HowItWorks from "../components/LandingPage/HowItWorks";
import MessageForm from "../components/LandingPage/MessageForm";
import ProductCarousel from "../components/LandingPage/ProductCarousel";
import WhatYouCanShare from "../components/LandingPage/WhatYouCanShare";
import data from "../Dummy-Data.json";
import { getCategory } from "../store/Actions/categoriesActionCreator";
import { getPosts } from "../store/Actions/postsActionCreator";
import { getProducts } from "../store/Actions/productsActionCreator";
import "./styles/Home.css";
import Frame1 from '../assets/Images/app-feature-slides/Frame-1.png';
import Frame2 from '../assets/Images/app-feature-slides/Frame-2.png';
import Frame3 from '../assets/Images/app-feature-slides/Frame-3.png';
import Frame4 from '../assets/Images/app-feature-slides/Frame-4.png';
import Frame5 from '../assets/Images/app-feature-slides/Frame-5.png';
import Frame6 from '../assets/Images/app-feature-slides/Frame-6.png';
import Frame7 from '../assets/Images/app-feature-slides/Frame-7.png';
import Frame8 from '../assets/Images/app-feature-slides/Frame-8.png';
import Frame9 from '../assets/Images/app-feature-slides/Frame-9.png';

// MObile
import Frame10 from '../assets/Images/app-feature-slides/Frame-10.png';
import Frame11 from '../assets/Images/app-feature-slides/Frame-11.png';
import Frame12 from '../assets/Images/app-feature-slides/Frame-12.png';
import Frame13 from '../assets/Images/app-feature-slides/Frame-13.png';
import Frame14 from '../assets/Images/app-feature-slides/Frame-14.png';
import Frame15 from '../assets/Images/app-feature-slides/Frame-15.png';
import Frame16 from '../assets/Images/app-feature-slides/Frame-16.png';
import Frame17 from '../assets/Images/app-feature-slides/Frame-17.png';
import Frame18 from '../assets/Images/app-feature-slides/Frame-18.png';

// interface Props {}
interface State {}
class Home extends Component<any, State> {
  featureImageData:any;
  constructor(props) {
    super(props);
    this.featureImageData = [
      {
        webImage: Frame1,
        mobileImage: Frame10
      },
      {
        webImage: Frame2,
        mobileImage: Frame11
      },
      {
        webImage: Frame3,
        mobileImage: Frame12
      },
      {
        webImage: Frame4,
        mobileImage: Frame13
      },
      {
        webImage: Frame5,
        mobileImage: Frame14
      },
      {
        webImage: Frame6,
        mobileImage: Frame15
      },
      {
        webImage: Frame7,
        mobileImage: Frame16
      },
      {
        webImage: Frame8,
        mobileImage: Frame17
      },
      {
        webImage: Frame9,
        mobileImage: Frame18
      }
    ]
  }
  componentDidMount() {
    this.props.getProducts();
    this.props.getCategories();
    
  }
  render() {
    const { products } = this.props;

    return (
      <div className="Home-Container">
        <Helmet>
          <title>TYC - Home</title>
        </Helmet>
        <HeroCarousel />
        <div className="extra-space"></div>
        <FeatureCarousel
          title="How It Works"
          description="MEANINGFUL INTERACTIONS THAT CREATE LASTING IMPRESSIONS"
          data={this.featureImageData}
          nslide={4}
        />

        <HowItWorks />

        <ProductCarousel
          title="Our Range Of Chips"
          data={products}
          nslide={3}
        />

        <WhatYouCanShare />

        <CTA />

        <div id="Form">
          <MessageForm />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    categories: state.CategoriesReducer.category,
    products: state.ProductsReducer.products,
    posts: state.PostsReducer.post,
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  getCategories: () => dispatch(getCategory()),
  getPosts: () => dispatch(getPosts()),
  getProducts: () => dispatch(getProducts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
