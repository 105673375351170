// loaders
export const LOGIN_ENABLE_LOADER = "LOGIN_ENABLE_LOADER";
export const LOGIN_DISABLE_LOADER = "LOGIN_DISABLE_LOADER";

//Login
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_RESPONSE = "LOGIN_RESPONSE";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOG_OUT = "LOG_OUT";
export const RESET_STORE = "RESET_STORE";
