import React, { Component } from "react";
import { IconContext } from "react-icons";
import { MdOutlineDisabledByDefault } from "react-icons/md";
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import styled from "styled-components";
import ProfilePic from "../assets/Images/Profile-Placeholder.png";
import PublicProfileTabs from "../components/PublicProfile/PublicProfileTabs";
import { API } from "../config/api.config";
import { PathApi } from "../config/api.path.config";
import apiClient from "../config/clients";
import VCard from 'vcard-creator';
import countryCodes from './CountriesCodes';
import { Field, Form } from "react-final-form";
import { FieldInput } from "../components/FormHelper/formhelper";
import "./styles/PublicProfile.css";
import PhoneInput from "react-phone-input-2";
import { composeValidators, required } from "../components/FormHelper/validtionhelper";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { shareContact } from "../services/userService";
// import DownloadBanner from "../components/PublicProfile/DownloadBanner";

class PublicProfile extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      userData: {},
      currentUserData: {},
      Profile: "",
      contacts: [],
      isActive: false,
      page: 1,
      activeDirect: "",
      isLoading: false,
      connectModal: false,
      countryCode: "",
      mobile: "",
      callingCode: {},
      location: {},
      locationError: "",
      isLocationError: false
    };
  }

  componentDidMount() {
    if(this.props.userData && Object.keys(this.props.userData).length) {
      this.setState({currentUserData: this.props.userData});
    }
    const pathname = window.location.pathname;
    if (pathname.includes("PublicProfile")) {
      this.setState({
        isLoading: true,
      });
      apiClient
        .get(PathApi.getNfcUserDetails + this.props.match.params.id)
        .then((data: any) => {
          if (data.data.isActive === true) {
            this.setState({
              userData: data.data,
              Profile: data.data.profileTabType,
              isActive: true,
              isLoading: false,
            });
          } else {
            this.setState({
              isActive: false,
              isLoading: false,
            });
          }
        })
        .catch((error: any) => {
          
          
          
          
        });
    } else if (pathname.includes("tags")) {
      this.setState({
        isLoading: true,
      });
      API.get(
        PathApi.getNfcDetailsBySerialNo + this.props.match.params.serialNumber
      )
        .then((data: any) => {
          if (data.data.data.user.isActive === true) {
            if (data.data.isDirect === true) {
              const allLinks = [
                ...(data.data.data.user.socialProfiles
                  ? data.data.data.user.socialProfiles
                  : null),
              ];
              allLinks.push(
                ...(data.data.data.user.businessProfiles
                  ? data.data.data.user.businessProfiles
                  : null)
              );
              this.setState(
                {
                  activeDirect: data.data.data.user.activeDirect,
                  isLoading: false,
                },
                () =>
                  allLinks.map((link: any) => {
                    link.id === this.state.activeDirect
                      ? (window.location.href = link.uri)
                      : console.log("");
                      return link;
                  })
              );
            } else {
              this.setState({
                userData: data.data.data.user,
                Profile: data.data.data.user.profileTabType,
                isActive: true,
                isLoading: false,
              });
            }
          } else {
            this.setState({
              isActive: false,
              isLoading: false,
            });
          }
        })
        .catch((error: any) => {
          this.setState({
            isActive: false,
            isLoading: false,
          });
        });
    } else {
      const postData = {
        query: `${this.props.match.params.id}`,
        type: "username",
      };
      this.setState({
        isLoading: true,
      });
      API.post(PathApi.searchById, postData)
        .then((response) => {
          // console.log("/p", response);
          if (response.data[0].isActive === true) {
            if (response.data[0].isDirect === true) {
              const allLinks = [
                ...(response.data[0].socialProfiles
                  ? response.data[0].socialProfiles
                  : null),
              ];
              allLinks.push(
                ...(response.data[0].businessProfiles
                  ? response.data[0].businessProfiles
                  : null)
              );
              this.setState(
                {
                  isLoading: false,
                  activeDirect: response.data[0].activeDirect,
                },
                () =>
                  allLinks.map((link: any) => {
                    link.id === this.state.activeDirect
                      ? (window.location.href = link.uri)
                      : console.log("");
                      return link;
                  })
              );
            } else {
              this.setState({
                userData: response.data[0],
                Profile: response.data[0].profileTabType,
                isActive: true,
                isLoading: false,
              });
            }
          } else {
            this.setState({
              isActive: false,
              isLoading: false,
            });
          }
        })
        .catch((error: any) => {});
    }
  }
  toggleConnectModal = () => this.setState({connectModal: !this.state.connectModal});
  retrieveLocation = () => {
    // retrieve location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((p) => this.success(p), () => this.error());
    } else {
      toast.error("Geolocation not supported");
      this.setState({locationError:"Need geolocation for to share the contact. Please provide the location access.", isLocationError: true});
    }
  }
  success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    this.setState({location: {latitude, longitude}});    
    
  }

  error() {
    toast.error("Unable to retrieve your location");
    this.setState({locationError:"Need geolocation for to share the contact. Please provide the location access.", isLocationError: true});
  }
  onConnectDetailsSubmit = async values => {
    const data = {
        ...values,
        countryCode: this.state.countryCode.toUpperCase(),
        location: this.state.location
    };
    try {
      const response = await shareContact(this.state.userData.id, data);
      if(response.status === 200) {
        toast.success("Contact Shared Successfully");
        this.toggleConnectModal();
        this.setState({currentUserData:{}});
      }
    } catch(e) {
      toast.error("Could not share the contact");
    }

  }
  PhoneAdapter = ({ input }) => (
    <PhoneInput
      {...input}
      enableSearch={true}
      placeholder="Mobile No."
      inputClass="ProfileInfo-Account__MobileInput"
      buttonClass="ProfileInfo-Account__MobileDropdown"
      country="us"
      onChange={(value, data: any) => {
        input.onChange(value);
        this.setState({
          countryCode: data.countryCode,
          mobile: value.slice(data.dialCode.length),
          callingCode: value,
        });
      }}
    />
  );

  // TODO: Implement "Download the app" banner.
  // getDeviceType() {
  //   const ua = navigator.userAgent;
  //   if (/iPad|iPhone|iPod/.test(ua)) {
  //       return 'iOS';
  //   } else if (/Android/.test(ua)) {
  //       return 'Android';
  //   }
  //   return 'Other';
  // }

  render() {
    console.log(this.state.userData);
    const { userData } = this.state;
    // const deviceType = this.getDeviceType();

    // Assume these utility functions are the same as in the mobile version
    const getLastName = (string) => {
      if (!string) return '';
      const arr = string.split(' ');
      return arr.length === 1 ? '' : arr[arr.length - 1];
    };

    const getFirstName = (string) => {
      if (!string) return '';
      const arr = string.split(' ');
      return arr.length === 1 ? string : arr.slice(0, -1).join(' ');
    };

    const getDiallingCode = (countryCode) => {
      let diallingCode = '';
      countryCodes.forEach((countryCodeDial) => {
        if (countryCodeDial.isoCode2 === countryCode) {
          diallingCode = countryCodeDial.countryCodes[0];
        }
      });
      return diallingCode;
    };

    type ParsedAddress = {
      street: string;
      city: string;
      stateZipCode: string;
      state: string;
      zipCode: string;
      countryCode: string;
    };

    const parseAddress = (address: string): ParsedAddress => {
      const parts = address.split(',');
      return {
        street: parts[0] || '',
        city: parts[1] || '',
        stateZipCode: parts[2] || '',
        state: parts[2] || '',
        zipCode: parts[3] || '',
        countryCode: parts[4] || '',
      };
    }


    const createAndSaveVCard = () => {
      let firstName = userData.firstName || '';
      let lastName = userData.lastName || '';

      if (!firstName && !lastName) {
          firstName = userData.name ? getFirstName(userData.name) : userData.username;
          lastName = userData.name ? getLastName(userData.name) : '';
      }

      const diallingCode = userData.countryCode ? getDiallingCode(userData.countryCode) : '';
      const parsedAddress = userData.address ? parseAddress(userData.address) : {} as ParsedAddress;;
      const phone = userData.mobile ? `+${diallingCode}${userData.mobile}` : '';
      const website = `${process.env.REACT_APP_URL}/${process.env.REACT_APP_PROFILE_LINK_PATH}/${userData.link}`

      const myVCard = new VCard();
      myVCard.addName(lastName, firstName);
      myVCard.addEmail(userData.email || '', 'Other');

      if (userData.address) {
          const { street, city, state, zipCode, countryCode } = parsedAddress;
          myVCard.addAddress(street, '', '', city, state, zipCode, countryCode, 'Home');
      }

      myVCard.addCompany(userData.company || '');
      if (userData.mobile) {
          myVCard.addPhoneNumber(phone, 'Mobile');
      }
      if (userData.notes) {
          myVCard.addNote(userData.notes);
      }
      if (website) {
        myVCard.addURL(website)
      }
      

      const blob = new Blob([myVCard.toString()], {type: "text/vcard;charset=utf-8"});
      const FileSaver = require('file-saver');
      FileSaver.saveAs(blob, `${firstName}${lastName}.vcf`);
    };

    const addContact = () => {
      if (userData.email || userData.address || userData.mobile) {
          createAndSaveVCard();
      }
    };


    const Xmark = styled(MdOutlineDisabledByDefault)`
      color: #ff9100;
      border-radius: 50px;
      border-radius: 2px;
      padding: 1px;
      margin-left: 40px;
      margin-top: 50px;
    `;
    return (
      <Container fluid className="PublicProfile">
        {this.state.isLoading ? (
          <Row className="PublicProfile_SpinnerContainer">
            <svg
              className="Spinner"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle className="Spinner_circle" cx="50" cy="50" r="46" />
            </svg>
          </Row>
        ) : (
          <>
            <Row>
              <Col
                xs={12}
                className="PublicProfile-Header PublicProfile-Header__ProfilePic"
              >
                <img
                  className="PublicProfile-ProfilePic"
                  src={userData.profilePic ? userData.profilePic : ProfilePic}
                  alt="Profile Pic"
                />
                {userData.name ?
                  <h3 className="PublicProfile-Username">{userData.name}</h3>
                :
                  <h3 className="PublicProfile-Username">{userData.username}</h3>
                }
                <button onClick={addContact} className="Dashboard-EditProfile">
                  Add Contact
                </button>
                <button className="Dashboard-EditProfile" onClick={() => {this.retrieveLocation(); this.toggleConnectModal();}}>
                  Connect
                </button>
              </Col>
            </Row>
            {this.state.isActive ? (
              <Row className="PublicProfile-Accounts">
                <Col xs={12}>
                  {" "}
                  <PublicProfileTabs userData={userData} currentUserData={this.state.currentUserData} />
                </Col>
              </Row>
            ) : (
              <Row className="PublicProfile-Accounts__Inactive">
                <Col xs={12}>
                  <h1>The TYC tag has not been activated.To start using the tag,<br/>please activate it using the Tap Your Chip mobile app</h1>
                </Col>
                <IconContext.Provider
                  value={{ color: "#ff9100", size: "200px" }}
                >
                  <div>
                    <Xmark />
                  </div>
                </IconContext.Provider>
              </Row>
            )}
          </>
        )}

        <Modal isOpen={this.state.connectModal} toggle={this.toggleConnectModal}>
            <ModalHeader>Share your details</ModalHeader>
            <ModalBody>
            <Form
            onSubmit={this.onConnectDetailsSubmit}
            initialValues={this.state.currentUserData}
            render={({ handleSubmit, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  {" "}
                  <Field
                    className="ProfileInfo-Input"
                    type={"text"}
                    component={FieldInput}
                    name="first_name"
                    placeholder="First Name"
                    validate={composeValidators(required)}
                  />{" "}
                  <Field
                    className="ProfileInfo-Input"
                    type={"text"}
                    component={FieldInput}
                    name="last_name"
                    placeholder="Last Name"
                    validate={composeValidators(required)}
                  />{" "}
                  <Field
                    name="phone"
                    placeholder="Mobile No."
                    component={this.PhoneAdapter}
                  />{" "} 
                  <Field
                    className="ProfileInfo-Input"
                    type={"email"}
                    component={FieldInput}
                    name="email"
                    placeholder="Email"
                    validate={composeValidators(required)}
                  />{" "}
                  <Field
                    className="ProfileInfo-Input"
                    type={"text"}
                    component={FieldInput}
                    name="company"
                    placeholder="Company"
                  />{" "}
                  
                  <Field
                    className="ProfileInfo-Input__textArea"
                    type={"textarea"}
                    component={FieldInput}
                    name="notes"
                    placeholder="Tell us something about yourself"
                  />
                  <div className="d-flex justify-content-between">
                    {
                      !this.state.isLocationError && 
                      <button className="ProfileInfo-Btns__Submit" type="submit">
                      Share Info
                    </button>
                    }
                    <Button type="button"
                      className="ProfileModal-CancelButton"
                      onClick={this.toggleConnectModal}
                    >
                      Cancel
                    </Button>
                  </div>
                  {
                      this.state.isLocationError && <p>{this.state.locationError}</p>
                    }
                </div>
              </form>
            )}
          />
            </ModalBody>
            {/* <ModalFooter>
              <Button
                className="ProfileModal-CancelButton"
                onClick={this.toggleConnectModal}
              >
                Back
              </Button>
            </ModalFooter> */}
          </Modal>
          {/* {deviceType !== 'Other' || <DownloadBanner deviceType={deviceType} />} */}
      </Container>
    );
  }
}
const mapStateToProps = (state: any, ownProps: any) => {
  return { userData: state.UserReducer };
};
export default connect(mapStateToProps, null)(PublicProfile);