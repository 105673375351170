import { IconContext } from "react-icons";
import iconConfig from "../../../iconHelper";
import AddLinkModal from "../ProfileAddLinkModal/AddLink";


const IconButton = ({ name, icon: Icon, color, profileType, size = '50px', entryType }) => (
  <AddLinkModal
    buttonTitle={name}
    buttonLogo={
      <IconContext.Provider value={{ size, color }}>
        <Icon />
      </IconContext.Provider>
    }
    type={profileType}
    name={name}
    entryType={entryType}
  />
);


const IconProfiles = (props) => {
  const { type, category } = props;
  return (
    <div>
      {iconConfig.map((media) => {
        if (media.category === category) {

          return (
            <IconButton 
              key={media.name}
              name={media.name}
              icon={media.icon}
              color={media.color}
              profileType={type}
              size="50px"
              entryType={media.entryType}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default IconProfiles;