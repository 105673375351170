import createSagaMiddleware from "@redux-saga/core";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage'
import RootReducer from "./index.reducer";

import watcherSaga from "./sagas/rootSaga";

const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['GuestCheckoutReducer']
}
const persistedReducer = persistReducer(persistConfig, RootReducer);
export const store: any = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

export const persistor = persistStore(store);
sagaMiddleware.run(watcherSaga);
