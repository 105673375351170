import { GuestCheckoutState, GuestData, GuestSubmitErrorType, GuestSubmitRequestType, GuestSubmitSuccessType } from "../../types/guest.type";
import { GUEST_CHECKOUT_ERROR, GUEST_CHECKOUT_INIT, GUEST_CHECKOUT_SUCCESS } from "../ActionTypes/guestCheckoutActionTypes";


const initialState: GuestCheckoutState = {};

export const GuestCheckoutReducer = (state: GuestCheckoutState = initialState, action: GuestSubmitRequestType | GuestSubmitSuccessType | GuestSubmitErrorType): GuestCheckoutState => {
    switch(action.type) {
        case GUEST_CHECKOUT_INIT:
            state.guestData = {...action.payload as GuestData};
            return state;
        case GUEST_CHECKOUT_SUCCESS:
            state.user = {...(action as GuestSubmitSuccessType).payload.user};
            state.message = (action as GuestSubmitSuccessType).payload.message;
            return state;
        case GUEST_CHECKOUT_ERROR:
            state.message = "";
            state.errorMessage = (action as GuestSubmitErrorType).payload;
            state.user = undefined;
            /*return {
                ...state,
                user: undefined,
                message: "",
                errorMessage: (action as GuestSubmitErrorType).payload
            };*/
            return state;
        default:
            return state;
    }
}