import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { connect } from "react-redux";
import { IconContext } from "react-icons";
import { FaTrashAlt } from "react-icons/fa";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { updateUserProfile } from "../../../../store/Actions/userActionCreator";
import { FieldInput } from "../../../FormHelper/formhelper";
import { validateEmail } from "../../../FormHelper/LinksValidations";
import "./AddLink.css";

const AddLinkModal = (props: any) => {
  const { className, image, icon, name, entryType } = props;
  const propName = name;
  const [modal, setModal] = useState(false);
  const initialFormState = { socialData: [{uri: "", title: ""}] };

  const toggle = () => setModal(!modal);

  const onSubmit = (values: any) => {
    const profileData = values.socialData.map(v => ({
      id: uuidv4(),
      image: image,
      count: 0,
      uri: v.uri,
      icon: icon,
      name: name,
      title: v.title
    }));

    if (props.type === "SOCIAL") {
      props.socialProfiles
        ? props.setProfile("socialProfiles", [
              ...props.socialProfiles,
              ...profileData,
          ])
        : props.setProfile("socialProfiles", profileData);
    } else {
      props.businessProfiles
        ? props.setProfile("BUSINESS", [...props.businessProfiles, ...profileData])
        : props.setProfile("BUSINESS", profileData);
    }

    setModal(false);
  };

  const validateLink = values => {
    const status = values && values.socialData && values.socialData.every(v =>  validateEmail(v.uri, props.name) === "");
    if(name === "Custom Link") {
      return values.socialData.every(v => !!v.title) && status;
    } else {
      return status;
    }
  }

  return (
    <div className="AddLink">
      <button className="AddLink-AddButton" onClick={toggle}>
          <Col xs={2}> {props.buttonLogo}</Col>
          <Col xs={4}> {props.buttonTitle}</Col>
          <Col xs={2}></Col>
      </button>
      <Form
        onSubmit={onSubmit}
        noValidate
        mutators={{...arrayMutators}}
        initialValues={initialFormState}
        render={({ handleSubmit, submitting, pristine, values }) => (
          <Modal
            isOpen={modal}
            toggle={toggle}
            className={`${className} AddLink-Modal`}>
            <ModalHeader className="AddLink-ModalContent" toggle={toggle}>
              Add Link . . .
            </ModalHeader>
            <ModalBody className="AddLink-ModalContent">
              <form className="AddLink-Form" onSubmit={handleSubmit}>
                <FieldArray name="socialData">
                  {
                    ({ fields }) => {
                      return (
                          <div>
                            {
                              fields.map((name, index) => (
                                  <div key={name}>
                                    <div className="d-flex align-items-center justify-content-between AddLinkWrap_div">
                                    {
                                      propName === "Custom Link" && <Field
                                      className="AddLink-Input"
                                      name={`${name}.title`}
                                      placeholder="Enter Title here"
                                      component={FieldInput}
                                    />
                                    }
                                      <Field
                                          className="AddLink-Input"
                                          name={`${name}.uri`}
                                          placeholder={`Enter ${entryType} here`}
                                          component={FieldInput}
                                      />
                                      {
                                        index > 0 ?
                                            <span onClick={() => fields.remove(index)}>
                                              <IconContext.Provider value={{ size: "22px", color: "#FF0000" }}>
                                                <FaTrashAlt />
                                              </IconContext.Provider>
                                            </span>
                                            : null
                                      }
                                    </div>
                                      {
                                          fields.value && fields.value[index]
                                            &&
                                          <p style={{ color: "red" }}>
                                              {validateEmail(fields.value[index].uri, props.name)}
                                          </p>
                                      }

                                  </div>
                              ))
                            }
                            {name === "Custom Link" && <Button onClick={() => fields.push({uri: ''})}>+ Add Link</Button>}
                          </div>
                      )
                    }
                  }
                </FieldArray>
              </form>
            </ModalBody>
            <ModalFooter className="AddLink-ModalContent">
              <Button
                className="AddLink-SubmitButton"
                onClick={handleSubmit}
                disabled={
                  !validateLink(values)
                }
              >
                Save Link
              </Button>
              <Button className="AddLink-CancelButton" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        )}
              />
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  setProfile: (profileType: any, request: any) => {
    dispatch(updateUserProfile(profileType, request));
  },
});
const mapStateToProps = (state: any) => {
  return {
    socialProfiles: state.UserReducer.socialProfiles,
    businessProfiles: state.UserReducer.businessProfiles,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLinkModal);
