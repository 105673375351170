import React from 'react';
import { IconContext } from 'react-icons';
import iconConfig from './iconHelper';

const RenderIcon = (props) => {
  const { name, size = '70px' } = props;

  if (name) {
    const icon = iconConfig.find(item => item.name === name);
    if (icon) {
      const IconComponent = icon.icon; 
      const iconColor = icon.color || undefined;
      return (
        <IconContext.Provider value={{ size, color: iconColor }}>
          <IconComponent size={size}/>
        </IconContext.Provider>
      );
    }
  }
  return null;
};

export default RenderIcon;