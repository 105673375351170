import { GUEST_CHECKOUT_ERROR, GUEST_CHECKOUT_INIT, GUEST_CHECKOUT_SUCCESS } from '../ActionTypes/guestCheckoutActionTypes';
import { GuestData, GuestResponse, GuestSubmitErrorType, GuestSubmitRequestType, GuestSubmitSuccessType } from '../../types/guest.type';

export const guestSubmitRequest = (payload: GuestData): GuestSubmitRequestType => {
    return {
        type: GUEST_CHECKOUT_INIT,
        isLoading: true,
        payload: payload
    }
}

export const guestSubmitSuccess = (payload: GuestResponse): GuestSubmitSuccessType => {
    return {
        type: GUEST_CHECKOUT_SUCCESS,
        isLoading: false,
        payload: payload
    }
}

export const guestSubmitError = (errorMessage: string): GuestSubmitErrorType => {
    return {
        type: GUEST_CHECKOUT_ERROR,
        payload: errorMessage,
        isLoading: false
    }
}