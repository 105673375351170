const getVisibleLinks = (data: any) => {
  const vis = data.filter((item: any) => {
    if (!item.hasOwnProperty("isVisible") || item.isVisible === true) {
      return item;
    }
  });
  console.log("vis", vis);
  return vis;
}

export default getVisibleLinks;
