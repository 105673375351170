import axios from "axios";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { IconContext } from "react-icons";
import { BsFillPlusSquareFill } from "react-icons/bs";
// import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
} from "reactstrap";
import { PathApi } from "../../../../config/api.path.config";

import { store } from "../../../../store/store";
import "./MediaUploadModal.css";
import {errorToast} from "../../../../utils/toasthelper";
import {Media} from "../../../../types/media.type";

const MediaUploadModal = (props) => {
  const { className } = props;

  const [modal, setModal] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [success, setSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState("Unknown");
  const toggle = () => setModal(!modal);

  const handleOnDrop = (files, rejectedFiles) => {
    setFile(files[0]);
  };
  const handleUpload = (files: File | null) => {
    if(!files) {
      errorToast("Please select a file.");
      return;
    }
    if (
      fileName === "Unknown" ||
      fileName === null ||
      fileName === undefined ||
      fileName === ""
    ) {
      errorToast("Please enter a file name.");
    } else {
      const token = store.getState().LoginReducer.userData.accessToken;
      const oFileExt = files.name.split(".").pop();
      const newName = oFileExt ? `${fileName}.${oFileExt}` : fileName;
      const newFile = new File([files], newName);
      let formData = new FormData();
      formData.append("file", newFile);
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };
      axios
        .post(
          `${PathApi.BASE_URL}${PathApi.userMediaUpload}${encodeURIComponent(
            files.type
          )}/${fileName}`,
          formData,
          {
            headers: headers,
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              let percent = loaded / total;
              console.log(
                `${loaded / 1048576}Mbs of ${total / 1048576}Mbs | ${
                  percent * 100
                }%`
              );
              setProgress(percent * 100);
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setSuccess(true);
            setTimeout(() => {
              toggle();
              // Reset the modal
              setFile(null);
              setFileName("");
              setSuccess(false);
              setProgress(0);
            }, 2000);
            props.onMediaAdded(res.data as Media);
            // setTimeout(() => window.location.reload(), 3000);
          }
        });
    }
  };

  const changeFileName = (fileName: string) => {
    setFileName(fileName);
    const oFileExt = file?.name.split(".").pop();
    console.log(oFileExt);
    if(fileName) {
      // file.name = oFileExt ? `${fileName}.${oFileExt}` : fileName;
      console.log(file);
      setFile(file);
    }
  }

  return (
    <div>
      <button className="MediaUploadModal-AddButton" onClick={toggle}>
        <IconContext.Provider value={{ size: "70px" }}>
          <div>
            <BsFillPlusSquareFill />
          </div>
        </IconContext.Provider>
        <p className="MediaUploadModal-AddButton__name">Add Media</p>
      </button>

      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={() => window.location.reload()}>
          Choose a media to upload
        </ModalHeader>
        <ModalBody className="MediaUploadModal-ModalBody">
          <label className="MediaUploadModal-Input__Label">
            Please enter a file name:{" "}
            <input
              className="MediaUploadModal-Input"
              type="text"
              onChange={(e) => setFileName(e.target.value)}
            />
          </label>

          <Dropzone onDrop={handleOnDrop}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  {...getRootProps()}
                  className={
                    success === false
                      ? `MediaUploadModal-Dnd`
                      : "MediaUploadModal-Success"
                  }
                >
                  <input {...getInputProps()} />
                  <p>
                    {file && file.name
                      ? `${file.name}`
                      : success === false
                      ? "Drag 'n' drop Media here, or click to select files"
                      : "Successfull"}
                  </p>
                </div>
                <Progress
                  animated
                  color={
                    success === false
                      ? "MediaUploadModal-ProgressBar"
                      : "success"
                  }
                  value={progress}
                  className="MediaUploadModal-Progress"
                  barClassName="MediaUploadModal-ProgressBar"
                />
              </section>
            )}
          </Dropzone>
        </ModalBody>
        <ModalFooter>
          <Button
            className="MediaUploadModal-Upload"
            onClick={() => handleUpload(file)}
          >
            Upload
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MediaUploadModal;
