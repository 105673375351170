import { useState } from "react";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import RenderIcon from "../../../RenderIcon";

import {
  deleteUserProfileItem,
  updateUserProfileItem,
} from "../../../../store/Actions/userActionCreator";
import { FieldInput } from "../../../FormHelper/formhelper";
import { validateEmail, validURLS } from "../../../FormHelper/LinksValidations";
import "./SocialModal.css";

const SocialModal = (props) => {
  const { className } = props;
  const getProfile = (type: string) => {
    switch (type) {
      case "SOCIAL":
        return "socialProfiles";
      case "BUSINESS":
        return "businessProfiles";
    }
  };
  const [modal, setModal] = useState(false);
  const profileType = getProfile(props.type);
  const openInNewTab = (uri: any) => {
    if (props.profile.name === "Email") {
      window.open(`mailto:${uri}`);
    } else if (props.profile.name === "Call") {
      window.open(`tel:${uri}`);
    } else if (props.profile.name === "Text") {
      window.open(`sms:${uri}`);
    } else if (props.profile.name === "Address") {
      window.open(`https://maps.google.com/?q= ${uri}`);
    } else {
      const newWindow = window.open(
        validURLS(uri, props.title),
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }
  };
  const handleDelete = async () => {
    console.log(profileType, props.profile.id);
    await props.deleteProfileItem(profileType, props.profile.id);
    setModal(false);
  };

  const onSubmit = async (values) => {
    props.updateProfileItem(profileType, { ...props.profile, uri: values.uri, title: values.title });
    setModal(false);
  };
  const toggle = () => setModal(!modal);

  return (
    <div
      className={`SocialModal ${
        props.userData.isDirect &&
        props.profile.id === props.userData.activeDirect
          ? "SocialModal-isDirect"
          : ""
      }`}
    >
      {props.userData.isDirect &&
        props.profile.id === props.userData.activeDirect && (
          <div className="SocialModal-DirectText">Direct</div>
        )}
      <button className="SocialModal-AddButton" onClick={toggle}>
        <RenderIcon name={props.profile.name} />
        <p className="SocialModal-AddButton__name">{props.profile.title || props.profile.name}</p>
      </button>

      <Form
        onSubmit={onSubmit}
        initialValues={{uri:props.profile.uri, title: props.profile.title || ""}}
        noValidate
        render={({ handleSubmit, values }) => (
          <Modal
            isOpen={modal}
            toggle={toggle}
            className={`${className} SocialModal-Modal`}
          >
            <ModalHeader className="SocialModal-ModalContent" toggle={toggle}>
              Edit this profile . . .
            </ModalHeader>
            <ModalBody className="SocialModal-ModalContent">
              <div className="SocialModal-ModalContent__Logo">
                <RenderIcon name={props.profile.name} />
              </div>
              <form className="SocialModal-Form" onSubmit={handleSubmit}>
              {
                props.profile.name === 'Custom Link' && <div>
                  <Field
                    className="SocialModal-Input"
                    name="title"
                    placeholder={props.profile.title || ""}
                    component={FieldInput}
                  />
                  <p className={"text-danger error"}>{!values.title && "Title is required"}</p>
                </div>
              }
                <div>
                  <Field
                    className="SocialModal-Input"
                    name="uri"
                    placeholder={props.profile.uri}
                    component={FieldInput}
                  />
                  <p className={"text-danger error"}>{validateEmail(values.uri, props.profile.name)}</p>
                </div>
              </form>
              <div className="SocialModal-CardBody__Buttons">
                <Button
                  className="SocialModal-OpenButton"
                  onClick={() => openInNewTab(`${props.profile.uri}`)}
                >
                  Open
                </Button>

                <Button
                  className="SocialModal-DeleteButton"
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </div>
            </ModalBody>
            <ModalFooter className="SocialModal-ModalContent">
              <Button
                className="SocialModal-SubmitButton"
                onClick={handleSubmit}
                disabled={validateEmail(values.uri, props.profile.name) !== "" || (props.profile.name === "Custom Link" && !values.title)}
              >
                Save
              </Button>
            </ModalFooter>
          </Modal>
        )}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  updateProfileItem: (profileType: any, request: any) =>
    dispatch(updateUserProfileItem(profileType, request)),
  deleteProfileItem: (profileType: any, id: string) =>
    dispatch(deleteUserProfileItem(profileType, id)),
});
function mapStateToProps(state: any) {
  return {
    userData: state.UserReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SocialModal);
