import classnames from "classnames";
import { useState } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import MediaCard from "../CustomComponents/MediaCard/MediaCard";
import { validURLS } from "../FormHelper/LinksValidations";
import RenderIcon from "../RenderIcon";
import "./PublicProfileTabs.css";
import apiClient from "../../config/clients";
import getVisibleLinks from "../../utils/visibleLinksHelper";

const PublicProfileTabs = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const openInNewTab = (uri: any, title: any, titleName: string, type: string) => {
    // Log the url clicked
    apiClient.post("/user-lick-clicked", {
      uri: uri, 
      id: props.userData.id, 
      name: title, 
      type: type, 
      title: titleName, 
      clicked_by_id: props.currentUserData.id
    }).then(r => console.log(r)).catch(e => console.log(e));
    if (title === "Email") {
      window.open(`mailto:${uri}`);
    } else if (title === "Call") {
      window.open(`tel:${uri}`);
    } else if (title === "Text") {
      window.open(`sms:${uri}`);
    } else {
      const newWindow = window.open(
        validURLS(uri, title),
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }
  };
  
  return (
    <div>
      <Nav className="PublicProfileTabs" tabs>
        {props.userData.profileTabType === "Personal" ? (
          <NavItem
            className={`PublicProfileTabs-Social__Solo ${
              activeTab === "1"
                ? "PublicProfileTabs-active"
                : "PublicProfileTabs-inactive"
            }`}
          >
            <NavLink
              className={`${classnames({
                active: activeTab === "1",
              })} `}
              onClick={() => {
                toggle("1");
              }}
            >
              SOCIAL
            </NavLink>{" "}
            {toggle("1")}
          </NavItem>
        ) : props.userData.profileTabType === "Business" ? (
          <NavItem
            className={`PublicProfileTabs-Business__Solo ${
              activeTab === "2"
                ? "PublicProfileTabs-active__BusinessSolo"
                : "PublicProfileTabs-inactive"
            }`}
          >
            <NavLink
              className={`${classnames({
                active: activeTab === "2",
              })}`}
              onClick={() => {
                toggle("2");
              }}
            >
              BUSINESS
            </NavLink>
            {toggle("2")}
          </NavItem>
        ) : (
          <>
            <NavItem
              className={`PublicProfileTabs-Social ${
                activeTab === "1"
                  ? "PublicProfileTabs-active"
                  : "PublicProfileTabs-inactive"
              }`}
            >
              <NavLink
                className={`${classnames({
                  active: activeTab === "1",
                })} `}
                onClick={() => {
                  toggle("1");
                }}
              >
                SOCIAL
              </NavLink>
            </NavItem>
            <NavItem
              className={`PublicProfileTabs-Business ${
                activeTab === "2"
                  ? "PublicProfileTabs-active"
                  : "PublicProfileTabs-inactive"
              }`}
            >
              <NavLink
                className={`${classnames({
                  active: activeTab === "2",
                })}`}
                onClick={() => {
                  toggle("2");
                }}
              >
                BUSINESS
              </NavLink>
            </NavItem>
          </>
        )}
      </Nav>
      <TabContent
        className="PublicProfileTabs-TabContent"
        activeTab={activeTab}
      >
        <TabPane tabId="1">
          <Row className="PublicProfileTabs-SocialProfile">
            {props.userData.socialProfiles &&
              getVisibleLinks(props.userData.socialProfiles).map((p: any) => {
                return (
                  <Col
                    xlg={1}
                    lg={2}
                    md={3}
                    sm={6}
                    xs={6}
                    className="PublicProfileTabs-LinkButton"
                  >
                    <button
                      className="PublicProfileTabs-LinkButton"
                      onClick={() => {
                        openInNewTab(p.uri, p.name, p.title, "social");
                        console.log(p.uri, p.name);
                      }}
                    >
                      <RenderIcon name={p.name} />
                      <p className="PublicProfileTabs-LinkButton__name">
                        {p.title || p.name}
                      </p>
                    </button>
                  </Col>
                );
              })}
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col className="p-0">
              <div className="PublicProfileTabs-Business__Content">
                <div>
                  <div className="PublicProfileTabs-Business__info">
                    {props.userData.bio}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div>
            <h3 className="PublicProfileTabs-SubTitles">Media</h3>
            <Row className="PublicProfileTabs-MediaSection__Header">
              {props.userData.medias &&
                props.userData.medias.map((m: any) => {
                  return (
                    <Col
                      xlg={1}
                      lg={2}
                      md={3}
                      sm={6}
                      xs={6}
                      className="PublicProfileTabs-MediaButton p-0"
                    >
                      <MediaCard media={m} />
                    </Col>
                  );
                })}
            </Row>
          </div>
          <div>
            <h3 className="PublicProfileTabs-SubTitles">Links</h3>
            <Row className="PublicProfileTabs-businessProfile">
              {props.userData.businessProfiles &&
                getVisibleLinks(props.userData.businessProfiles).map((p: any) => {
                  return (
                    <Col
                      xlg={1}
                      lg={2}
                      md={3}
                      sm={6}
                      xs={6}
                      className="PublicProfileTabs-LinkButton"
                    >
                      <button
                        className="PublicProfileTabs-LinkButton"
                        onClick={() => openInNewTab(p.uri, p.name, p.title, "business")}
                      >
                        <RenderIcon name={p.name} />
                        <p className="PublicProfileTabs-LinkButton__name">
                          {p.title || p.name}
                        </p>
                      </button>
                    </Col>
                  );
                })}
            </Row>
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default PublicProfileTabs;
