import React, { Component } from "react";
import { IconContext } from "react-icons";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./styles/FeatureCarousel.css";
import { Col, Container, Row } from "reactstrap";

interface Props {
  data: { id: number; text: string; name: string }[];
  title: any;
  description: string;
  nslide: number;
}

interface State {}

export default class FeatureCarousel extends Component<Props, State> {
  slider: any;
  mobileSlider: any;
  constructor(props: any) {
    super(props);

    this.slider = React.createRef();
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
    this.mobileSlider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
    this.mobileSlider.slickPrev();
  }

  handleClick() {}

  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      speed: 500,
      pauseOnHover: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 2150,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            settings: "unslick"
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            centerMode: false,
            dots: false,
            settings: "unslick"
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
            centerMode: false,
            settings: "unslick"
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            settings: "unslick"
          },
        },
        {
          breakpoint: 0,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            settings: "unslick"
          },
        },
      ],
    };

    const mobileSliderSettings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      speed: 500,
      pauseOnHover: true,
      arrows: false,
      settings: "unslick",
      responsive: [
        {
          breakpoint: 2150,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            settings: "unslick"
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            settings: "unslick"
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            settings: "unslick"
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            centerMode: false,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
            centerMode: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 0,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
          },
        },
      ],
    };


    return (
      <div className="Feature-Container">
        <p id="Features" className="Feature-SectionMsg">
          {this.props.description}
        </p>
        <div className="Feature-header">
          <h2 className="Feature-title">{this.props.title}</h2>
          <div className="Feature-Arrow__div">
            <button className="Feature-Arrow" onClick={this.previous}>
              <IconContext.Provider value={{ size: "40px" }}>
                <div>
                  <BsArrowLeft className="Arrow" />
                </div>
              </IconContext.Provider>
            </button>
            <button className="Feature-Arrow" onClick={this.next}>
              <IconContext.Provider value={{ size: "40px" }}>
                <div>
                  <BsArrowRight />
                </div>
              </IconContext.Provider>
            </button>
          </div>
        </div>

        <Slider
          className="Feature-Slider desktop-view"
          ref={(c) => (this.slider = c)}
          {...settings}
        >
          {this.props.data.map((feat: any) => {
            return (
              <div>
                <Container className="Hero-Container Hero-Container2">
                  <Row className="Hero-Row">
                    <Col className="Hero-ImgContainer" lg="12">
                      <img className="feature-slide" src={feat.webImage} alt="" />
                    </Col>
                  </Row>
                </Container>
              </div>
            );
          })}
        </Slider>

        <Slider
          className="Feature-Slider mobile-view"
          ref={(c) => (this.mobileSlider = c)}
          {...mobileSliderSettings}
        >
          {this.props.data.map((feat: any) => {
            return (
              <div>
                <Container className="Hero-Container Hero-Container2">
                  <Row className="Hero-Row">
                    <Col className="Hero-ImgContainer" lg="12">
                      <img className="feature-slide" src={feat.mobileImage} alt="" />
                    </Col>
                  </Row>
                </Container>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}
