export const validateEmail = (value: string, name: string) => {
  if (!value) {
    return "Required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) &&
    name === "Email"
  ) {
    return "Invalid Email Address. Please Enter Correct Format.";
  } else if (
    !/^[\w](?!.*?\.{2})[\w.]{1,28}[\w]$/i.test(value) &&
    name === "Instagram"
  ) {
    return "Invalid Instagram Username. Please Enter Correct Format.";
  } else if (
    !/^[\w](?!.*?\.{2})[\w.]{1,28}[\w]$/i.test(value) &&
    name === "Telegram"
  ) {
    return "Invalid Telegram Username. Please Enter Correct Format.";
  } else if (
    !/^[\w](?!.*?\.{2})[\w.]{1,13}[\w]$/i.test(value) &&
    name === "Snapchat"
  ) {
    return "Invalid Snapchat Username. Please Enter Correct Format.";
  } else if (
    !/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{2,24}$/i.test(value) &&
    name === "Tiktok"
  ) {
    return "Invalid Tiktok Username. Please Enter Correct Format.";
  } else if (!/^[a-zA-Z0-9_]{4,15}$/i.test(value) && name === "Twitter") {
    return "Invalid Twitter Username. Please Enter Correct Format.";
  } else if (
    !/^(https?:\/\/)?(www\.)?linkedin\.com\/in\/([a-zA-Z0-9\-_]+)(\/?|\?(.*))?$/i.test(
      value
    ) &&
    name === "Linkedin"
  ) {
    return "Invalid Linkedin Link. Please Enter Correct Format.";
  } else if (
    !/^(?:(?:http|https):\/\/)?(?:www.|m.)?facebook.com\/(?!home.php)(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w.-]+)$/i.test(
      value
    ) &&
    name === "Facebook"
  ) {
    return "Invalid Facebook Link. Please Enter Correct Format.";
  } else if (
    !/^^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$$/i.test(
      value
    ) &&
    name === "Youtube"
  ) {
    return "Invalid Youtube Link. Please Enter Correct Format.";
  } else if (
    !/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/i.test(
      value
    ) &&
    name === "Pinterest"
  ) {
    return "Invalid Pinterest Link. Please Enter Correct Format.";
  } else if (!/^(#)?[a-zA-Z0-9][\w]{2,24}$/i.test(value) && name === "Twitch") {
    return "Invalid Twitch Username. Please Enter Correct Format.";
  } else if (
    !/^[\w](?!.*?\.{2})[\w.]{1,28}[\w]$/i.test(value) && name === 'Threads') {
      return 'Invalid Threads Username. Please Enter Correct Format.'
  } else if (
    !/^(https:\/\/)?(www\.)?discord\.gg\/[a-zA-Z0-9]+$/i.test(value) && name === 'Discord') {
      return 'Invalid Discord link. Please Enter Correct Format.'
  } else if (
    !/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/i.test(
      value
    ) &&
    name === "Onlyfans"
  ) {
    return "Invalid Onlyfans Link. Please Enter Correct Format.";
  } else if (!/^[0-9]{4,24}$/i.test(value) && name === "Whatsapp") {
    return "Invalid Contact Number.";
  } else if (!/^[0-9]{4,24}$/i.test(value) && name === "WeChat") {
    return "Invalid Contact Number.";
  } else if (!/^[0-9]{4,24}$/i.test(value) && name === "Zelle") {
    return "Invalid Contact Number.";
  } else if (!/^[0-9]{4,24}$/i.test(value) && name === "Text") {
    return "Invalid Contact Number.";
  } else if (!/^[0-9]{4,24}$/i.test(value) && name === "Call") {
    return "Invalid Contact Number.";
  } else if (!/^[0-9]{4,24}$/i.test(value) && name === "Contact Card") {
    return "Invalid Contact Number.";
  } else if (
    !/^[a-zA-Z0-9\s]+,\s[a-zA-Z0-9\s]+,\s[a-zA-Z\s]+\s[0-9]+,\s[a-zA-Z\s]*$/i.test(
      value
    ) &&
    name === "Address"
  ) {
    return "Invalid Address.";
  } else if (
    !/^(https:\/\/open.spotify.com(.*))$/i.test(
      value
    ) &&
    name === "Spotify"
  ) {
    return "Invalid Spotify Link. Please Enter Correct Format.";
  } else if (
    !/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/i.test(
      value
    ) &&
    name === "Apple Music"
  ) {
    return "Invalid Apple Music Link. Please Enter Correct Format.";
  } else if (
    !/^https?:\/\/(soundcloud\.com|snd\.sc)\/(.*)$/i.test(
      value
    ) &&
    name === "Soundcloud"
  ) {
    return "Invalid Sound Cloud Link. Please Enter Correct Format.";
  } else if (!/^paypal\.me\/.+$/i.test(value) && name === "Paypal") {
    return "Invalid Paypal Link. Please Enter Correct Format.";
  } else if (!/^[a-zA-Z0-9_-]{5,16}$/i.test(value) && name === "Venmo") {
    return "Invalid Venmo Username. Please Enter Correct Format.";
  } else if (!/^(?:\s*[#$][_a-z\d-]+)+$$/i.test(value) && name === "Cash App") {
    return "Invalid Cash Tag. Please Enter Correct Format.";
  } else if (
    !/^https?:\/\/([w]{3}\.)?(linktr.ee)\/(.*)$/i.test(
      value
    ) &&
    name === "Linktree"
  ) {
    return "Invalid Linktree Link. Please Enter Correct Format.";
  } else if (
    !/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/i.test(
      value
    ) &&
    name === "Calendly"
  ) {
    return "Invalid Calendly Link. Please Enter Correct Format.";
  } else if (!/^(https:\/\/)?(www\.)?etsy\.com\/listing\/[0-9]+(\/[a-zA-Z0-9_-]+)?(\?.*)?$/i.test(value) && name === "Etsy") {
    return "Invalid Etsy Link. Please Enter Correct Format.";
  } else if (
    !/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/i.test(
      value
    ) &&
    name === "Yelp"
  ) {
    return "Invalid Yelp Link. Please Enter Correct Format.";
  } else if (!/^(https:\/\/)?(www\.)?zillow\.com\/homedetails\/([a-zA-Z0-9- ]+)\/[0-9]+_zpid\/$/i.test(value) && name === "Zillow") {
    return "Invalid Zillow Link. Please Enter Correct Format.";
  } else if (!/^(https:\/\/)?(www\.)?google\.\w+\/.*/i.test(value) && name === "Google Reviews") {
    return "Invalid Google Reviews Link. Please Enter Correct Format.";
  } else if (!/^(https:\/\/)?(www\.)?apps\.apple\.com\/([a-zA-Z]{2})\/app\/([a-zA-Z0-9-_]+)\/id[0-9]+$/i.test(value) && name === "App Link") {
    return "Invalid App Store Link. Please Enter Correct Format.";
  } else if (!/^(((https:\/\/)?booksy\.com.*)|([a-zA-Z0-9-]+\.booksy\.com))$/i.test(value) && name === "Booksy") {
    return "Invalid Booksy Link. Please Enter Correct Format.";

  } else if (
    !/^((http|https):\/\/)?((www)\.)?([a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|((http|https):\/\/)?((www)\.)?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[a-zA-Z0-9]+\.[^\s]{2,}|[a-zA-Z0-9]+\.[^\s]{2,})$/i.test(
      value
    ) &&
    name === "Custom Link"
  ) {
    return "Invalid Custom Link. Please Enter Correct Format.";
  } else {
    return "";
  }
};

export const validURLS = (str: any, title: any) => {
  if (title === "Instagram") {
    return "https://instagram.com/" + str;
  } else if (title === "Snapchat") {
    return "https://www.snapchat.com/add/" + str;
  } else if (title === 'Threads') {
    return `https://www.threads.net/@${str}`;
  } else if (title === "Twitter") {
    return "https://twitter.com/" + str;
  } else if (title === "Tiktok") {
    return "https://www.tiktok.com/" + str;
  } else if (title === "Twitch") {
    return "https://www.twitch.tv/" + str;
  } else if (title === "Whatsapp") {
    return "https://wa.me/" + str;
  } else if (title === 'Wechat') {
    return `https://u.wechat.com/${str}`;
  } else if (title === 'Zelle') {
    return `https://enroll.zellepay.com/${str}`;
  } else if (title === "Cash App") {
    return "https://cash.app/" + str;
  } else if (title === "Venmo") {
    return "https://venmo.com/u/" + str;
  } else if (title === "Telegram") {
    return "https://t.me/" + str;
  } else if (
    title === "Onlyfans" ||
    title === "Calendly" ||
    title === "Yelp" ||
    title === "Linktree" ||
    title === "Custom Link" ||
    title === "Address" ||
    title === "Pinterest" ||
    title === "Youtube" ||
    title === "Linkedin" ||
    title === "Spotify" ||
    title === "Apple Music" ||
    title === "Sound Cloud" ||
    title === "Facebook" ||
    title === 'Paypal' ||
    title === 'Discord' ||
    title === 'Google Reviews' ||
    title === 'Etsy' ||
    title === 'App Link' ||
    title === 'Zillow' ||
    title === 'Booksy'
  ) {
    if (str.indexOf("http://") === 0 || str.indexOf("https://") === 0) {
      return str;
    } else {
      return "https://" + str;
    }
  }
};
