import React, { Component } from "react";
import { Field, Form } from "react-final-form";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { FieldInput } from "../components/FormHelper/formhelper";
import { guestSubmitRequest } from "../store/Actions/guestCheckoutActionCreator"
import { DispatchCheckoutType, GuestCheckoutState, GuestData } from "../types/guest.type"

class GuestCheckoutForm extends Component<any, GuestCheckoutState> {
    
    constructor(props) {
        super(props);
        console.log(props, "PROPERTIES OF GUEST");
        
        this.state = {
            errorMessage:"",
            message:"",
            user: undefined,
            guestData: {
                firstName:"",
                lastName:"",
                email:"",
                address:""
            }
        };
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<GuestCheckoutState>, snapshot?: any): void {
        console.log(prevProps, "PROPS");
        console.log(prevState, "STATE");
    }

    submitForm = async (values) => {
        console.log(values, "VALUES....");
        
        this.props.saveGuestDetails(values);
    }

    render() {
        return <>
            <Container fluid className="p-0">
                <Helmet>
                    <title>TYC - Guest Info</title>
                </Helmet>
                <Row>
                    <Col xs={12} className="Contacts-Header__Title">
                        <h3 className="Contacts-Header__Title">Personal Info</h3>
                    </Col>
                </Row>
                <Row className="justify-content-center mb-2">
                    <Col xs={12} lg={6}>
                        <Form 
                            onSubmit={this.submitForm}
                            initialValues={this.state.guestData}
                            render={({ handleSubmit, submitting, pristine, values }) => (
                                <form className="SignUp-Form" onSubmit={handleSubmit}>
                                    <Field 
                                        className="SignUp-Input"
                                        name="firstName" 
                                        type="text"
                                        component={FieldInput}
                                        placeholder="First Name" />

                                    <Field 
                                        className="SignUp-Input"
                                        name="lastName" 
                                        type="text"
                                        component={FieldInput}
                                        placeholder="Last Name" />

                                    <Field 
                                        className="SignUp-Input"
                                        name="email" 
                                        type="text"
                                        component={FieldInput}
                                        placeholder="Email" />

                                    <Field 
                                        className="SignUp-Input"
                                        name="address" 
                                        type="text"
                                        component={FieldInput}
                                        placeholder="Address" />
                                         <button
                                            className={`SignUp-Btns__Submit`}
                                            type="submit"
                                            disabled={
                                            submitting || pristine
                                            }
                                        >
                                            Save & Continue
                                    </button>
                                </form>
                            )}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center mb-2">
                    <Col lg={6} xs={12}>
                        <div className="SignUp-SignInSection">
                            <p>Already a User? </p>
                            <Link className="SignUp-SignInSection__Link" to="/SignIn">
                                Sign In
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    }
}

const mapStateToProps = (state: any) => {
    return {
        ...state.GuestCheckoutReducer
    }
};

const mapDispatchToProps = (dispatch: DispatchCheckoutType) => ({
    saveGuestDetails: (data: GuestData) => dispatch(guestSubmitRequest(data ?? {firstName:"", lastName:"", email:""}))
});

export default connect(mapStateToProps, mapDispatchToProps)(GuestCheckoutForm);

