import axios from 'axios';
import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { PathApi } from '../config/api.path.config';

export default withRouter(class OrderConfirmed extends Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            orderId:"",
            status:"",
            numberOftags: "",
            totalPrice: "",
            currency: "",
            items: []
        };
    }

    componentDidMount(): void {
        const {orderId, status} = this.props.match.params;
        this.setState({
            status: status
        });
        if(status === 'success') {
            axios.get(`${PathApi.BASE_URL}/orders/${orderId}`).then(response => {
                this.setState({
                    orderId: response.data.orderId,
                    numberOftags: response.data.items.reduce((acumelator, current) => {return acumelator + parseInt(current.quantity)}, 0),
                    totalPrice: response.data.totalAmount,
                    currency: response.data.currency,
                    items: response.data.items
                });
            }).catch(err => {
                console.log(err, "ERROR");
            });
        }
    }

    render() {
        return (
            <div className='container mt-4 mb-4'>
                <div className='row justify-content-center'>
                    <div className='col-6'>
                        <h4>Your order is {this.state.status === 'success' ? 'confirmed' : 'failed'}.</h4>
                        { this.state.status === 'success' && <p>Your order will be delivered within 24 hours.</p>}
                    </div>
                    <div className='col-12'>
                    </div>
                    <div className='col-6'>
                        <div className='row'>
                        <div className='col-12'>
                        <h5>Item details</h5>
                        <div className='row'>
                        {
                            this.state.items && this.state.items.map(item => {
                                return <>
                                    <div className='col-12'>
                                        <p className='pb-1 mb-0 font-weight-bold'>{item.name}</p>
                                        <p className='pb-1 mb-0'>{item.quantity} x {item.unit_amount.value} = {parseInt(item.quantity) * parseFloat(item.unit_amount.value)}</p>
                                    </div>
                                </>
                            })
                        }
                        </div>
                    </div>
                    <div className='col-12'>
                        <hr/>
                        <p className='pb-1 mb-0'>
                            <span className='font-weight-bold'>Order Id: </span>
                            <span>{this.state.orderId || "N/A"}</span>
                        </p>
                        <p className='pb-1 mb-0'>
                            <span className='font-weight-bold'># of tags: </span>
                            <span>{this.state.numberOftags || "N/A"}</span>
                        </p>
                        <p className='pb-1 mb-0'>
                            <span className='font-weight-bold'>Total Price: </span>
                            <span>{this.state.totalPrice || "N/A"} {(this.state.totalPrice && this.state.currency) || ""}</span>
                        </p>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});