import { call, put } from "redux-saga/effects";
import { saveGuestData } from "../../../services/userService";
import { GuestSubmitRequestType } from "../../../types/guest.type";
import { guestSubmitError, guestSubmitSuccess } from "../../Actions/guestCheckoutActionCreator";
import {guestCheckoutProceed} from "../../Actions/cartActionCreator";

export function* handleSaveGuest(action: GuestSubmitRequestType) {
    try {
        console.log(action, "Guest saga action");
        const response = yield call(saveGuestData, action.payload);
        if(response.status === 200) {
            // Success
            const {data} = response;
            console.log(data, "RETURNED USER DATA");
            localStorage.setItem("tyc-gid", data.user.id);
            yield put(guestSubmitSuccess(data));
            // yield the add guest id to cart session
            yield put(guestCheckoutProceed(data.user.id));
            window.location.href = "/Cart";
        } else {
            // error
            console.log(response, "EROR FROM GUEST SAVE AXIOS");
            
            yield put(guestSubmitError(response.response?.message || "Something went wrong"));
        }

    } catch (e) {
        console.log(e, "ERROR IN GUEST SAGA");
    }
}