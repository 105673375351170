import {
  AiOutlineMail,
  AiOutlineWechat,
} from "react-icons/ai";
import { BsMusicNoteBeamed } from "react-icons/bs";
import {
  FaDiscord,
  FaFacebook,
  FaLinkedinIn,
  FaPaypal,
  FaPinterest,
  FaSoundcloud,
  FaSpotify,
  FaTwitch,
  FaTwitter,
  FaWhatsappSquare,
  FaYoutube,
  FaYelp, 
  FaEtsy, 
  FaAppStore
} from "react-icons/fa";
import { FcAddressBook, FcCalendar } from "react-icons/fc";
import { GrMap } from "react-icons/gr";
import { IoCall, IoLogoVenmo } from "react-icons/io5";
import { SiCashapp, SiLinktree, SiOnlyfans, SiTiktok, SiZelle, SiZillow } from "react-icons/si";

import styled from "styled-components";
import Customm from '../assets/Images/customimage.png';
import iMessageIcon from "../assets/Images/msg.png";
import Google from '../assets/Images/google.png';
import Booksy from '../assets/Images/booksy.png';
import Snapchat from "../assets/Images/snapchat.png";
import instagram from "../assets/Images/instagram.png";
import telegram from "../assets/Images/telegram.png";
import email from "../assets/Images/email.png";

import { FaThreads } from "react-icons/fa6";

const AppleMusic = styled(BsMusicNoteBeamed)`
  background: linear-gradient(
    -45deg,
    #35c3f3 0%,
    #8b9fe8 20%,
    #e681d8 39%,
    #ffa9a4 76%,
    #fed2ce 100%
  );
  color: #fff;
  padding: 5px;
  border-radius: 10px;
  margin-left: 2.5px;
`;
const Soundcloud = styled(FaSoundcloud)`
  background: #fe5000;
  color: #fff;
  padding: 5px;
  border-radius: 10px;
  margin-left: 2.5px;
`;
const Wechat = styled(AiOutlineWechat)`
  background: #7bb32e;
  color: #fff;
  padding: 7px;
  border-radius: 10px;
  margin-left: 2.5px;
`;
const Yelp = styled(FaYelp)`
  background: #f43939;
  color: #fff;
  padding: 7px;
  border-radius: 10px;
  margin-left: 2.5px;
`;
const Call = styled(IoCall)`
  background: linear-gradient(
    174deg,
    rgba(137, 224, 14, 1) 74%,
    rgba(126, 215, 0, 1) 81%
  );
  color: #fff;
  padding: 7px;
  border-radius: 10px;
  margin-left: 2.5px;
`;

const TextIcon = ({ size = '50px' }) => <img alt={"Message"} src={iMessageIcon} style={{width: size}} />;
const CustomLinkIcon = ({ size = '50px' }) => <img src={Customm} width={size} height={size} alt="" />;
const GoogleReviewIcon = ({ size = '50px' }) => <img alt={"Google Review"} src={Google} style={{width: size}} />;
const BooksyIcon = ({ size = '50px' }) => <img alt={"Booksy"} src={Booksy} style={{width: size}} />;
const SnapchatIcon = ({ size = '50px' }) => <img alt={"Snapchat"} src={Snapchat} style={{width: size}} />;
const InstagramIcon = ({ size = '50px' }) => <img alt={"Instagram"} src={instagram} style={{width: size}} />;
const TelegramIcon = ({ size = '50px' }) => <img alt={"Telegram"} src={telegram} style={{width: size}} />;
const EmailIcon = ({ size = '50px' }) => <img alt={"Email"} src={email} style={{width: size}} />;

// TODO: refactor iconConfig to use these constants
// const categories = {
//   social: "social",
//   payment: "payment",
//   music: "music",
//   contact: "contact",
//   more: "more"
// };

// const entryTypes = {
//   username: "username",
//   link: "link",
//   number: "number",
//   email: "email",
//   address: "address"
// };

const iconConfig = [
  { name: "Instagram", icon: InstagramIcon, color: null, category: "social", entryType: "username" },
  { name: "Facebook", icon: FaFacebook, color: "#3b5998", category: "social", entryType: "link" },
  { name: "Snapchat", icon: SnapchatIcon, color: "#FFFC00", category: "social", entryType: "username" },
  { name: "Twitter", icon: FaTwitter, color: "#1DA1F2", category: "social", entryType: "username" },
  { name: "Linkedin", icon: FaLinkedinIn, color: "#0077b5", category: "social", entryType: "link" },
  { name: "Youtube", icon: FaYoutube, color: "#FF0000", category: "social", entryType: "link" },
  { name: "Pinterest", icon: FaPinterest, color: "#E60023", category: "social", entryType: "link" },
  { name: "Tiktok", icon: SiTiktok, color: "#000", category: "social", entryType: "username" },
  { name: "Twitch", icon: FaTwitch, color: "#6441A4", category: "social", entryType: "username" },
  { name: "Onlyfans", icon: SiOnlyfans, color: "#00AFF0", category: "social", entryType: "link" },
  { name: "Threads", icon: FaThreads, color: null, category: "social", entryType: "username" },
  // TODO: add Discord
  // { name: "Discord", icon: FaDiscord, color: "rgb(101, 78, 203)", category: "social", entryType: "link" },
  { name: "Paypal", icon: FaPaypal, color: "#00457C", category: "payment", entryType: "link" },
  { name: "Venmo", icon: IoLogoVenmo, color: "#3D95CE", category: "payment", entryType: "username" },
  { name: "Cash App", icon: SiCashapp, color: "#00C244", category: "payment", entryType: "number" },
  { name: "Zelle", icon: SiZelle, color: "rgb(125 0 194)", category: "payment", entryType: "number" },
  { name: "Apple Music", icon: AppleMusic, color: null, category: "music", entryType: "link" },
  { name: "Spotify", icon: FaSpotify, color: "#1DB954", category: "music", entryType: "link" },
  { name: "Soundcloud", icon: Soundcloud, color: null, category: "music", entryType: "link" },
  { name: "Email", icon: EmailIcon, color: null, category: "contact", entryType: "email" },
  { name: "Whatsapp", icon: FaWhatsappSquare, color: "#25d366", category: "contact", entryType: "number" },
  { name: "Telegram", icon: TelegramIcon, color: null, category: "contact", entryType: "username" },
  { name: "WeChat", icon: Wechat, color: null, category: "contact", entryType: "number" },
  { name: "Address", icon: GrMap, color: null, category: "contact", entryType: "address" },
  { name: "Contact Card", icon: FcAddressBook, color: null, category: "contact", entryType: "number" },
  { name: "Call", icon: Call, color: null, category: "contact", entryType: "number" },
  { name: "Text", icon: TextIcon, color: null, category: "contact", entryType: "number" },
  { name: "Yelp", icon: Yelp, color: null, category: "more", entryType: "link" },
  { name: "Linktree", icon: SiLinktree, color: "#25d366", category: "more", entryType: "link" },
  { name: "Calendly", icon: FcCalendar, color: null, category: "more", entryType: "link" },
  { name: "Google Reviews", icon: GoogleReviewIcon, color: "rgb(180, 180, 180)", category: "more", entryType: "link" },
  { name: "Etsy", icon: FaEtsy, color: "rgb(255 118 79)", category: "more", entryType: "link" },
  { name: "App Link", icon: FaAppStore, color: "rgb(37 132 211)", category: "more", entryType: "link" },
  { name: "Zillow", icon: SiZillow, color: "rgb(37 94 211)", category: "more", entryType: "link" },
  { name: "Booksy", icon: BooksyIcon, color: "rgb(37 190 211)", category: "more", entryType: "link" },
  { name: "Custom Link", icon: CustomLinkIcon, color: null, category: "more", entryType: "link" },
];

export default iconConfig;